'use client';

import Image from 'next/image';

import { Heading, Text } from '@/app/components/Typography';

export default function NotFound() {
  return (
    <div className='flex h-screen flex-col'>
      <div className='px-18 mx-auto flex max-w-5xl flex-1 flex-col items-center gap-x-6 pt-10 sm:flex-row sm:px-20'>
        <div className='mb-6'>
          <Image
            alt='404 not found'
            src={{ width: 406, height: 492.07, src: '/images/404.svg' }}
          />
        </div>

        <div className='max-w-[25.375rem]'>
          <Heading as='h1' size='lg'>
            Error 404
          </Heading>
          <Heading as='h2' size='2xl' className='mb-2'>
            Page not found
          </Heading>
          <Text as='p' className='mb-8 text-blueGray-500'>
            The page you are looking for doesn’t exist or another error
            accurred. Go back, or head over to Menu to choose a new direction.
          </Text>

          <button
            className='flex items-center gap-x-3 text-sm font-semibold text-purpleBlue-600'
            onClick={() => window.history.back()}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}
